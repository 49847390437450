import React from 'react'
import { Link } from 'gatsby'
import Title from '../page-title'
import ArchivedProject from './archived-project'
import Arrow from '../../assets/svg/arquetipo-arrow.svg'

const Archived = ({ projects }) => (
  <React.Fragment>
    <Title title='Archived' number={projects.length}/>
    <div className='archived-list grid'>
      <div className='left'>
        {projects.slice(0, 8).map((project, i) => {
          const { name, date, surface, address } = project

          return(
            <ArchivedProject key={i*1000} name={name} date={date} surface={surface} address={address} i={i}/>
          )
        })}
      </div>
      <div className='right'>
        {projects.slice(8).map((project, i) => {
          const { name, date, surface, address } = project

          return(
            <ArchivedProject key={i*5000} name={name} date={date} surface={surface} address={address} i={i}/>
          )
        })}
      </div>
      <Link id='cta-projects' className='cta' to='/' data-sal='slide-down' data-sal-delay='300' data-sal-duration='1000' data-sal-easing='ease-out'>Back Home <Arrow/></Link>
    </div>
  </React.Fragment>
)

export default Archived
