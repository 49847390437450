import React from 'react';
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Title from '../components/page-title'
import Grid from '../components/projects/grid'
import Archived from '../components/projects/archived-list'

export const query = graphql`
  query ProjectsQuery {
    projects: allSanityProject(sort: {fields: _createdAt, order: DESC}) {
      totalCount
      nodes {
        outsideCover {
          asset {
            fluid(maxWidth: 980) {
              ...GatsbySanityImageFluid_noBase64
            }
          }
        }
        title
        slug {
          current
        }
        insideCover {
          asset {
            fluid(maxWidth: 980) {
              ...GatsbySanityImageFluid_noBase64
            }
          }
        }
      }
    }
    archived: allSanityArchivedProjects {
      totalCount
      nodes {
        projects {
          name
          date
          surface
          address
        }
      }
    }
  }
`

const ProjectsPage = ({data}) => {
  const { projects, archived } = data

  return(
    <Layout>
      <SEO title='Projects'/>
      <Title title='Projects' number={projects.totalCount}/>
      <Grid projects={projects.nodes} />
      <Archived projects={archived.nodes[0].projects}/>
    </Layout>
  )
}

export default ProjectsPage
