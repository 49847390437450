import React from 'react'
import ProjectPreview from './project-preview'

const Grid = ({ projects }) => {
  return(
    <div className='projects-grid'>
      {projects.map((project, i) => {
        return(
          <ProjectPreview key={i*100} last={projects.length === i + 1} cover={project.outsideCover.asset.fluid} secondCover={project.insideCover.asset.fluid} title={project.title} slug={project.slug.current} i={i+1}/>
        )
      })}
    </div>
  )
}

export default Grid
