import React from 'react'
import { Link } from 'gatsby'
import Classnames from 'classnames'
import Image from '../image'
import Arrow from '../../assets/svg/arquetipo-arrow.svg'

const ProjectPreview = ({ cover, secondCover, title, slug, last, i }) => {

  let previewClassnames = Classnames({
    'preview last' : !!last,
    'preview' : !last,
  })

  return (
    <div className={previewClassnames}>
      <Link to={`/projects/${slug}`} className='covers-container'>
        <Image className={slug} fluid={cover} alt={`${title} - Arquetipo Estudio`}/>
        <Image className={`${slug} second-cover`} fluid={secondCover} alt={`${title} - Arquetipo Estudio`}/>
      </Link>
      <div className='texts desktop'>
        <h3>{title}</h3>
        <Link to={`/projects/${slug}`}>See more</Link>
      </div>
      <div className='texts mobile'>
        <Link className='cta' to={`/projects/${slug}`}>{title}<Arrow/></Link>
      </div>
    </div>
  )
}

export default ProjectPreview
